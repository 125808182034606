const KEY_LOCAL_STORAGE = 'previous-pages';

const getPreviousPages = () => JSON.parse(localStorage.getItem(KEY_LOCAL_STORAGE));

const setPreviousPages = (pathName) => {
  const pages = getPreviousPages(KEY_LOCAL_STORAGE) ? getPreviousPages(KEY_LOCAL_STORAGE) : [];

  if (pathName && !pages.includes(pathName)) {
    pages.push(pathName);
  }

  if (pages.length > 0) {
    localStorage.setItem(KEY_LOCAL_STORAGE, JSON.stringify(pages));
  }

  return null;
};

export { getPreviousPages, setPreviousPages };
