/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const { setPreviousPages } = require('utils/previous-pages-storage');

// You can delete this file if you're not using it
require('./src/styles/app.scss');

// function to jump to the correct scroll position when following a link with a anchor
function scrollToAnchor(location) {
  const { hash } = location;

  if (!hash) return;

  window.onload = function () {
    window.requestAnimationFrame(() => {
      const anchor = document.querySelector(hash);

      if (!anchor) return;

      const offset = anchor.getBoundingClientRect().top + window.scrollY;

      window.scroll({ top: offset });
    });
  };
}

exports.onRouteUpdate = ({ location }) => {
  setPreviousPages(location.pathname);
  scrollToAnchor(location);
};

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.state && location.state.preventScroll === true) {
    return false;
  }

  return true;
};
